import React, { Component } from 'react';
import { Route } from 'react-router';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { NPCContainer } from './NPCContainer';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <NavMenu />
                <Container>
                    <Route path='/npc-tracker' component={NPCContainer} />
                </Container>
            </div>
        );
    }
}
