import React, { Component } from 'react';
import './NPCTracker.css';
import { AllaSearch } from './AllaSearch';
import { CountDown } from './CountDown';

export class NPCTracker extends Component {
    static displayName = NPCTracker.name;

    constructor(props) {
        super(props);

        this.state = { npcs: [], loading: true, server: this.props.server };
    }

    componentDidMount() {
        this.populateData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.callId !== prevProps.callId) {
            this.populateData();
        }
    }

    static renderTable(npcs) {
        return (
            <div className='row npcs'>
                {npcs.map(npc =>
                    <div className='col-xs-12 col-sm-6 col-md-6 col-lg-3' key={npc.name}>
                        <div className='col-xs-12'>
                            <div className='npc'>
                                <div className='npc-name container-header' title={npc.name}>
                                    <AllaSearch npc={npc.name} iconMode={false} />
                                </div>
                                <div className='npc-shortname' title={npc.shortName}>({npc.shortName})</div>
                                <div className='npc-note' title={npc.note}>{npc.note}</div>
                                <div className='npc-spawns'>
                                    <table width='100%'>
                                        <tbody>
                                            {npc.spawns.map(spawn =>
                                                <tr className='npc-spawn' key={spawn.key}>
                                                    <td className='npc-spawn-time text-left' title={spawn.spawnTime}>
                                                        <CountDown windowStart={spawn.windowStart} windowEnd={spawn.windowEnd} hasWindow={spawn.hasWindow} windowText={spawn.windowText} />
                                                    </td>
                                                    <td className='npc-spawn-instance text-right'>{spawn.instance}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : NPCTracker.renderTable(this.state.npcs);

        return (
            <div className='col-xs-12 npcs-container'>
                {contents}

                <div className='col-xs-12'>
                    <ul>
                        <li className='qc-green'>
                            The NPC does not have a spawn-window and will spawn when the timer reaches zero.
                        </li>                        
                        <li className='qc-red'>
                            The NPC is overdue. It might be up or have been killed already.
                        </li>
                        <li >
                            <span className="material-icons-outlined md-18">schedule</span>: This NPC has a spawn-window. Hover over the icon for details.
                        </li>
                        <ul>
                            <li className='qc-green'>
                                <span className="material-icons-outlined md-18">schedule</span> The NPC is currently in their spawn-window. Might be up.
                            </li>
                            <li className='qc-orange'>
                                <span className="material-icons-outlined md-18">schedule</span> The NPC is not yet in window.
                            </li>
                            <li className='qc-red'>
                                <span className="material-icons-outlined md-18">schedule</span> The NPC has exited their spawn-window and is overdue.
                            </li>
                        </ul>

                    </ul>
                </div>
            </div>
        );
    }

    async populateData() {

        var server = this.state.server;
        const response = await fetch('/npctracker/GetNPCs?server=' + server);

        const data = await response.json();
        this.setState({ npcs: data, loading: false, server: server });
    }
}
