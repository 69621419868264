import React, { Component } from 'react';
import './NPCNextUp.css';
import { AllaSearch } from './AllaSearch';
import { CountDown } from './CountDown';

export class NPCNextUp extends Component {
    static displayName = NPCNextUp.name;

    constructor(props) {
        super(props);

        this.state = { npcs: [], loading: true, server: this.props.server };
    }

    componentDidMount() { 
        this.populateData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.callId !== prevProps.callId) {
            this.populateData();
        }
    }

    static renderTable(npcs) {
        return (
            <div className='col-xs-12'>
                <div className='nextup'>
                    <div className='nextup-header container-header'>
                        <span className="material-icons">alarm</span> Next up
                        </div>
                    <div className='nextup-content'>
                        <table>
                            <tbody>
                                {npcs.map(spawn =>
                                     

                                    <tr className='npc-spawn' key={spawn.key} title={spawn.note}>
                                        <td className='npc-spawn-time text-left'>
                                            <CountDown windowStart={spawn.windowStart} windowEnd={spawn.windowEnd} hasWindow={spawn.hasWindow} windowText={spawn.windowText} />
                                        </td>
                                        <td className='npc-spawn-name text-left'>
                                            <AllaSearch npc={spawn.name} />
                                        </td>
                                        <td className='npc-spawn-instance text-right'>{spawn.instance}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : NPCNextUp.renderTable(this.state.npcs);

        return (
            <div className='col-xs-12 col-lg-6'>
                {contents}
            </div>
        );
    }

    async populateData() {

        var server = this.state.server;
        const response = await fetch('/npctracker/GetNextUp?server=' + server);

        const data = await response.json();
        this.setState({ npcs: data, loading: false, server: server });
    }
}
