import React, { Component } from 'react';
import { NPCTracker } from './NPCTracker';
import { NPCNextUp } from './NPCNextUp';
import { NPCOverdue } from './NPCOverdue';
import { ViewCounter } from './ViewCounter';
import { setupSignalRConnection } from './qcsignalr';

const connectionHub = '/hubs/notifications';

export class NPCContainer extends Component {
    static displayName = NPCContainer.name;
    static server = '';
    static hasUpdatesPending = false;

    constructor(props) {
        super(props);

        const query = new URLSearchParams(this.props.location.search);
        this.server = query.get('server');
        this.state = { callId: 0, viewCount: 0};

        const setupEventsHub = setupSignalRConnection(connectionHub, {
            receiveMessage: (res) => {
                if (res.server === this.server) {
                    this.timerChanged();
                }
            },
            viewCountChanged: (res) => {
                if (res.server === this.server) {
                    this.viewCountChanged(res.count);
                }
            }
        }, this.server);
        setupEventsHub();

        this.startRefreshLoop();
    }

    timerChanged() {        
        this.hasUpdatesPending = true;        
    }

    startRefreshLoop() {        
        let refreshTimerId = setInterval(() => {            
            if (this.hasUpdatesPending) {                
                this.hasUpdatesPending = false;
                this.setState({
                    callId: Date.now()
                });
            }
        }, 2000);       
    }

    viewCountChanged(newCount) {        
        this.setState({
            viewCount: newCount
        });
    }

    render() {
        return (
            <div>
                <div className='row'>
                    <NPCNextUp server={this.server} callId={this.state.callId} />
                    <NPCOverdue server={this.server} callId={this.state.callId} />
                </div>
                <div className='row'>
                    <NPCTracker server={this.server} callId={this.state.callId} />
                </div>
                <div className='row'>
                    <ViewCounter viewCount={ this.state.viewCount } />
                </div>
                <button id='refreshButton' onClick={() => this.timerChanged()}>hitme</button>                
            </div>
        );
    }

}
