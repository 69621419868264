import React, { Component } from 'react';
import './AllaSearch.css';

export class AllaSearch extends Component {
    static displayName = AllaSearch.name;

    constructor(props) {
        super(props);

        this.state = {
            npc: props.npc,
            iconMode: props.iconMode
        };
    }

    render() {
        const allaUrl = 'https://everquest.allakhazam.com/search.html?q=';
        
        let url = allaUrl + this.state.npc;
        let title = 'Search for ' + this.state.npc + ' on Allakhazam';
        var content = this.state.iconMode ? <span className="material-icons">open_in_new</span> : this.state.npc ;

        return (
            <a href={url} target='_blank' rel='noopener noreferrer' title={title}>{content}</a>
        );
    }
}
