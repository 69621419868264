import React, { Component } from 'react';

export class ViewCounter extends Component {
    static displayName = ViewCounter.name;

    constructor(props) {
        super(props);

        this.state = { viewCount: 0 };
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : <ul>
                <li>
                    <span className="material-icons-outlined md-18">visibility</span> Clients connected: {this.props.viewCount}
                </li>
            </ul>

        return (
            <div className='col-xs-12'>
                {contents}                    
            </div>
        );
    }
}
