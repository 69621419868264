import React, { Component } from 'react';
import './CountDown.css';
import HumanizeDuration from 'react-humanize-duration';

export class CountDown extends Component {
    static displayName = CountDown.name;
    static windowText = '';
    static hasWindow = false;
    static windowStart = 0;
    _input: ?HTMLInputElement;

    constructor(props) {
        super(props);
        this.windowText = this.props.windowText;
        this.hasWindow = this.props.hasWindow;
        this.windowStart = new Date(this.props.windowStart);
        this.windowEnd = new Date(this.props.windowEnd);

        this.state = { timeLeft: this.getTimeLeft(this.props.windowEnd) };
    }

    componentDidMount() {
        this.interval = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    tick() {
        var timeLeft = this.getTimeLeft(this.windowEnd);

        this.setState(state => ({
            timeLeft: timeLeft
        }));

        if (timeLeft < 0) {            
            if (!this.hasParentClass(this._input, 'overdue')) {
                document.getElementById("refreshButton").click();
            }            
        }
    }

    getTimeLeft(windowEnd) {
        return windowEnd - new Date();
    }

    render() {

        var timeLeft = this.state.timeLeft;
        var containerClass = 'countdown-container';

        if (new Date() > this.windowEnd) {
            containerClass += ' countdown-red';
        }
        else if (this.hasWindow && this.windowEnd - this.windowStart > timeLeft) {
            containerClass += ' countdown-green';
        }
        else if (this.hasWindow) {
            containerClass += ' countdown-orange';
        }
        else {
            containerClass += ' countdown-green';
        }

        return (
            <div className={containerClass} ref={c => (this._input = c)}>
                <HumanizeDuration duration={timeLeft} language={{ largest: 2, round: true }} />
                {this.hasWindow &&
                    <span className="material-icons-outlined md-18" title={this.windowText}>
                        schedule
                    </span>
                }
            </div>
        );
    }

    hasParentClass(child, classname) {
        if (child.className.split(' ').indexOf(classname) >= 0) return true;
        try {
            //Throws TypeError if child doesn't have parent any more
            return child.parentNode && this.hasParentClass(child.parentNode, classname);
        } catch (TypeError) {
            return false;
        }
    }
}
